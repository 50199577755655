import React from "react"
import BlogPost from "../blog/blogPost"
import { BlogPostType } from "../blog/blogPostSection"

type Props = {
  posts: BlogPostType[]
}

const AboutProfileSection = ({ posts }: Props) => {
  return (
    <div className="container">
      <article className="g-5 row">
        {posts.map((p, i) => (
          <div
            data-sal="fade"
            data-sal-duration="800"
            data-sal-delay="400"
            data-sal-easing="ease"
            key={i}
            className={"col-12 col-md-6 col-lg-3"}
          >
            <BlogPost {...p} />
          </div>
        ))}
      </article>
    </div>
  )
}

export default AboutProfileSection
